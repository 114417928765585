import React from 'react';
import Linkify from 'linkify-react';

const LinkifyWrapper = ({ children }) => {
  const options = {
    attributes: {
      rel: 'noopener noreferrer',
      target: '_blank',
    },
  };

  return (
    <Linkify options={options}>
      {children}
    </Linkify>
  );
};

export default LinkifyWrapper;
